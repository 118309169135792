import { captureException, captureMessage, Scope } from '@sentry/react';
import { inject, injectable } from 'inversify';
import { combineLatest, filter, startWith, switchMap, take } from 'rxjs';

import type { Staff } from '@/services/companies/company.service';
import { type Company, UserService } from '@/services/user.service';
import { AuthService } from '../auth.service';

@injectable()
export class LogService {
  private myStaff: Staff | null = null;
  private myCompany: Company | null = null;

  constructor(
    @inject(AuthService) private authService: AuthService,
    @inject(UserService) private userService: UserService,
  ) {
    this.authService
      .getIsAuthenticated$()
      .pipe(
        filter((t) => t),
        take(1),
        switchMap(() =>
          combineLatest({
            myStaff: this.userService.getMyStaff$().pipe(startWith(null)),
            myCompany: this.userService.getMyCompany$().pipe(startWith(null)),
          }),
        ),
      )
      .subscribe(({ myStaff, myCompany }) => {
        this.myStaff = myStaff;
        this.myCompany = myCompany;
      });
  }

  public log(message: string, ...data: any[]) {
    try {
      console.log(message, ...data);
    } catch (_e) {
      console.error('Unable to log the data.');
      console.error(message);
    }

    try {
      const scope = new Scope();

      scope.setUser({
        id: this.myStaff?.id,
        email: this.myStaff?.email,
        staffId: this.myStaff?.staffId,
        companyId: this.myCompany?.id,
      });

      scope.setLevel('info');

      captureMessage(message, scope);
    } catch (_e) {
      console.error('Unable to capture the log.');
      console.error(message);
    }
  }

  public error(message: string | any, ...data: any[]) {
    try {
      console.error(message, ...data);
    } catch (_e) {
      console.error('Unable to log the data.');
      console.error(message);
    }

    try {
      const scope = new Scope();

      scope.setUser({
        id: this.myStaff?.id,
        email: this.myStaff?.email,
        staffId: this.myStaff?.staffId,
        companyId: this.myCompany?.id,
      });

      scope.setLevel('error');

      captureException(message, scope);
    } catch (_e) {
      console.error('Unable to capture the error log.');
      console.error(message);
    }
  }
}
