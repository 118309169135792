export const TICKETING_TEST_IDS = {
  ticketingCreateTicketDialog: 'ticketing-create-ticket-dialog',
  ticketingViewRelatedConversationButton:
    'ticketing-view-related-conversation-button',
  ticketingAssigneeDropdown: 'ticketing-assignee-dropdown',
  ticketingAssigneeDropdownMenu: 'ticketing-assignee-dropdown-menu',
  ticketingAssigneeFilterInput: 'ticketing-assignee-filter-input',
  ticketingAssigneeFilterMenu: 'ticketing-assignee-filter-menu',
  ticketingCreateTicketAssigneeInput: 'ticketing-create-ticket-assignee-input',
  ticketingCreateTicketAssigneeMenu: 'ticketing-create-ticket-assignee-menu',
  ticketingListItem: (id: string) => `ticketing-list-item-${id}`,
  ticketingEditTicketStatusDropdown: 'ticketing-edit-ticket-status-dropdown',
  ticketingEditTicketTypeDropdown: 'ticketing-edit-ticket-type-dropdown',
  ticketingEditTicketPriorityDropdown:
    'ticketing-edit-ticket-priority-dropdown',
  ticketingEditTicketAssigneeDropdownInput:
    'ticketing-edit-ticket-assignee-dropdown-input',
  ticketingEditTicketAssigneeDropdownMenu:
    'ticketing-edit-ticket-assignee-dropdown-menu',
} as const;
