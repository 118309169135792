export const BROADCAST_TEST_IDS = {
  broadcastsTableSearchInput: 'broadcasts-table-search-input',
  broadcastsTableCreateNewBroadcastButton:
    'broadcasts-table-create-new-broadcast-button',
  broadcastsTableCreateNewBroadcastDialog:
    'broadcasts-table-create-new-broadcast-dialog',
  broadcastsTableCreateNewBroadcastDialogNextButton:
    'broadcasts-table-create-new-broadcast-dialog-next-button',
  broadcastsTableCreateNewBroadcastChannelSelect:
    'broadcasts-table-create-new-broadcast-channel-select',
  broadcastsTable: 'broadcasts-table',
  broadcastsDetailSaveDraftButton: 'broadcasts-detail-save-draft-button',
  broadcastsDetailPublishButton: 'broadcasts-detail-publish-button',
  broadcastsDetailCloudAPIBroadcastNameInput:
    'broadcasts-detail-cloudapi-broadcast-name-input',
  broadcastsDetailCloudAPIBroadcastTimeRadio:
    'broadcasts-detail-cloudapi-broadcast-time-radio',
  broadcastsDetailCloudAPIMessageTypeSwitch:
    'broadcasts-detail-cloudapi-message-type-switch',
  broadcastsDetailCloudAPIMessageInput:
    'broadcasts-detail-cloudapi-message-input',
  broadcastsDetailCloudAPIMessageInputManualTextInput:
    'broadcasts-detail-cloudapi-message-manual-text-input',
  broadcastsDetailCloudAPIClearTemplateButton:
    'broadcasts-detail-cloudapi-clear-template-button',
} as const;
