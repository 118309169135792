import { useIsOverflow } from '@/hooks/useIsOverflow';
import { Tooltip, TooltipProps, useForkRef } from '@mui/material';
import { ReactNode, useRef } from 'react';

interface EllipsisTooltipProps extends Omit<TooltipProps, 'title'> {
  title?: ReactNode;
}

export const EllipsisTooltip = ({
  title,
  children,
  ...props
}: EllipsisTooltipProps) => {
  const [measureRef, isOverflow] = useIsOverflow<HTMLElement>();
  const ref = useRef<HTMLElement>(null);

  return (
    <Tooltip
      ref={useForkRef(measureRef, ref)}
      disableHoverListener={!isOverflow}
      disableTouchListener={!isOverflow}
      title={title || ref.current?.textContent}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
