import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

interface DismissInfo {
  dismissed: boolean;
  timestamp: string;
}

const DEFAULT_DISMISS_INFO: DismissInfo = {
  dismissed: false,
  timestamp: '',
};
const STORAGE_KEY = 'planMigrationDismissInfo';

//Avoid to use useLocalStorage because it will trigger initial set with undefined storageKey
const useDailyPlanMigrationAlertDismiss = (companyId: string) => {
  const [dismissInfo, setDismissInfo] =
    useState<DismissInfo>(DEFAULT_DISMISS_INFO);
  const storageKey = companyId ? `${STORAGE_KEY}-${companyId}` : '';

  const handleDismiss = () => {
    if (!storageKey) return;

    const newDismissInfo = {
      dismissed: true,
      timestamp: new Date().toISOString(),
    };
    setDismissInfo(newDismissInfo);
    localStorage.setItem(storageKey, JSON.stringify(newDismissInfo));
  };

  useEffect(() => {
    if (!storageKey) return;

    const storedValue = localStorage.getItem(storageKey);
    let storedDismissInfo = DEFAULT_DISMISS_INFO;
    if (storedValue) {
      storedDismissInfo = JSON.parse(storedValue);
      setDismissInfo(storedDismissInfo);
    }

    const lastDismissDate = storedDismissInfo.timestamp;
    const isExpired =
      !lastDismissDate ||
      dayjs().startOf('day').isAfter(dayjs(lastDismissDate).startOf('day'));

    if (isExpired) {
      setDismissInfo(DEFAULT_DISMISS_INFO);
      localStorage.setItem(storageKey, JSON.stringify(DEFAULT_DISMISS_INFO));
    }
  }, [storageKey]);

  const showAlert = !dismissInfo?.dismissed;

  return {
    showAlert,
    handleDismiss,
  };
};

export default useDailyPlanMigrationAlertDismiss;
