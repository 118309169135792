// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import { useQueryClient } from '@tanstack/react-query';
import { clear } from 'idb-keyval';

const clearPersistedQueryCache = clear;

export const useAuth = () => {
  const auth0 = useAuth0();
  const queryClient = useQueryClient();

  const logout = useCallback(
    async (options?: LogoutOptions) => {
      mixpanel.reset();
      await Promise.all([auth0.logout(options), clearPersistedQueryCache()]);
      queryClient.clear();
    },
    [auth0, queryClient],
  );

  return useMemo(() => ({ ...auth0, logout }), [auth0, logout]);
};
