export enum StripePaymentStatus {
  PENDING = 'Pending',
  PAID = 'Paid',
  CANCELED = 'Canceled',
  REFUNDED = 'Refunded',
  PARTIAL_REFUND = 'PartialRefund',
  REFUND_FAILED = 'RefundFailed',
  REFUND_CANCELED = 'RefundCanceled',
  REFUND_PENDING = 'RefundPending',
}
