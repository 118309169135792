import dayjs from 'dayjs';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { TravisBackendCompanyDomainModelsCondition } from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import {
  AnalyticsBroadcastMtricsResponseType,
  AnalyticsCommonMtrics,
  AnalyticsCommonMtricsResponseType,
} from './types';
import { useAxios } from './axiosClient';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { SegmentList } from '@/services/analytics/types';

export interface AnalyticsAdvancedFilter {
  logicalOperator: string;
  filters: AdvancedFilter[];
}

export interface AdvancedFilter {
  fieldName: string;
  operator: string;
  values: string[];
}

export type AnalyticsGroupBy = 'day' | 'week' | 'month';

export interface GetCommonMetricsParams {
  from: string;
  to: string;
  conditions?: TravisBackendCompanyDomainModelsCondition[];
  advancedFilter?: AnalyticsAdvancedFilter;
  groupBy: AnalyticsGroupBy;
}

export interface GetBroadcastMetricsParams {
  from: string;
  to: string;
  conditions?: TravisBackendCompanyDomainModelsCondition[];
}

export const analyticsKeys = createQueryKeys('analytics', {
  getCommonMetrics: (params: GetCommonMetricsParams) => ({
    metric: 'common',
    ...params,
  }),
  getBroadcastMetrics: (params: GetBroadcastMetricsParams) => ({
    metric: 'broadcastMetrics',
    ...params,
  }),
  getSegments: () => ['segment'],
});

export function useAnalyticsCommonMetricsOptions(
  params: GetCommonMetricsParams,
  enabled: boolean = true,
) {
  // const url = '/Company/Analytics/Conversation/Common';
  const url = '/Company/Analytics/Conversation/Common';
  const axios = useAxios();
  return queryOptions({
    queryKey: analyticsKeys.getCommonMetrics(params),
    queryFn: async () => {
      const { groupBy: _, ...apiParams } = params;
      const response = await axios.post<AnalyticsCommonMtricsResponseType>(
        url,
        apiParams,
      );
      return _transformConversationData(response.data, params);
    },
    staleTime: 20 * 60 * 1000, // 20 mins
    enabled,
  });
}

const DATE_FORMAT = 'YYYY-MM-DD';

const GROUP_BY_TRANSFORM_MAP: Record<
  AnalyticsGroupBy,
  {
    startOf: dayjs.OpUnitType;
  }
> = {
  day: {
    startOf: 'day',
  },
  week: {
    startOf: 'week',
  },
  month: {
    startOf: 'month',
  },
};

function _transformConversationData(
  data: AnalyticsCommonMtricsResponseType,
  params: GetCommonMetricsParams,
) {
  const { groupBy } = params;

  if (groupBy === 'day') {
    return data;
  }

  // For the case where the first few days, which their grouped starting week/month, are before the first date of the date range
  // If so, they should be grouped as the first date of the date range
  // e.g. Date Range: 2024-01-14(Tue) - xxxx-xx-xx, Group By: Weekly
  // 2024-01-14 - 2024-01-19(Sun) should be grouped as 2024-01-14(Tue) instead of 2024-01-13(Mon)
  let noOfDateGroupAsFirstDate = 0;
  for (let i = 0; i < data.dailyLogs.length; i++) {
    if (
      dayjs(data.dailyLogs[i].date)
        .startOf(GROUP_BY_TRANSFORM_MAP[groupBy].startOf)
        .isSameOrAfter(dayjs(data.dailyLogs[0].date))
    ) {
      noOfDateGroupAsFirstDate = i + 1;
      break;
    }

    if (i === data.dailyLogs.length - 1) {
      // if it reaches the last date and no date has start week after the first date of the date range.
      // all dates should be grouped as the first date of the date range
      noOfDateGroupAsFirstDate = data.dailyLogs.length;
    }
  }

  const dateDataMap = new Map<string, AnalyticsCommonMtrics>();

  data.dailyLogs.forEach((log, index) => {
    const groupedDate =
      index < noOfDateGroupAsFirstDate
        ? dayjs(data.dailyLogs[0].date).format(DATE_FORMAT)
        : dayjs(log.date)
            .startOf(GROUP_BY_TRANSFORM_MAP[groupBy].startOf)
            .format(DATE_FORMAT);

    if (dateDataMap.has(groupedDate)) {
      const existingLog = dateDataMap.get(groupedDate)!;
      dateDataMap.set(
        groupedDate,
        Object.fromEntries(
          Object.entries(log).map(([metricName, value]) => {
            // guard string value
            if (typeof value === 'string') {
              return [metricName, value];
            }

            return [
              metricName,
              value +
                Number(
                  existingLog?.[metricName as keyof AnalyticsCommonMtrics] || 0,
                ),
            ];
          }),
        ) as AnalyticsCommonMtrics,
      );
      return;
    }

    dateDataMap.set(groupedDate, log);
  });

  return {
    ...data,
    dailyLogs: Array.from(
      dateDataMap.entries().map(([date, log]) => ({
        ...log,
        date,
      })),
    ),
  };
}

export function useAnalyticsBroadcastMetricsOptions(
  params: GetBroadcastMetricsParams,
  enabled: boolean = true,
) {
  const url = '/Company/Analytics/Conversation/Broadcast';
  const axios = useAxios();
  return queryOptions({
    queryKey: analyticsKeys.getBroadcastMetrics(params),
    queryFn: async () => {
      const response = await axios.post<AnalyticsBroadcastMtricsResponseType>(
        url,
        params,
      );
      return response.data;
    },
    staleTime: 20 * 60 * 1000, // 20 mins
    enabled,
  });
}

export function useAnalyticsSegmentsQuery() {
  const axios = useAxios();

  return useQuery({
    queryKey: analyticsKeys.getSegments(),
    queryFn: async () => {
      const response = await axios.get<SegmentList[]>(
        '/Company/Analytics/Segment',
      );
      return response.data.toSorted((a, b) => a.name.localeCompare(b.name));
    },
  });
}
