export const SETTINGS_TEST_IDS = {
  settingsLayoutHeader: 'settings-layout-header',
  settingsAddProfilePictureButton: 'settings-add-profile-picture-button',
  settingsMenuDrawer: 'settings-menu-drawer',
  settingsMenuToggleButton: 'settings-menu-toggle-button',
  settingsMenuCloseButton: 'settings-menu-close-button',
  settingsMenu: 'settings-menu',
  settingsMenuToggle: 'settings-menu-toggle',
  settingsDataPrivacyTableActionMenu: (id: string | number) =>
    `settings-data-privacy-table-action-menu-${id}`,
  settingsDataPrivacyTableActionMenuButton: (id: string | number) =>
    `settings-data-privacy-table-action-menu-button-${id}`,
  settingsDataPrivacyTableSearchInput:
    'settings-data-privacy-table-search-input',
  settingsDataPrivacyEditPatternDialog:
    'settings-data-privacy-edit-pattern-dialog',
  settingsRolesAndPermissionsDeleteDialog:
    'settings-roles-and-permissions-delete-dialog',
  settingsUserManagementSearchUserInput:
    'settings-user-management-search-user-input',
  settingsIntegrationDisconnectionWhatsappPhoneInput: (index: number) =>
    `settings-integration-disconnection-whatsapp-phone-input-${index}`,
  settingsIntegrationDisconnectionWhatsappDisconnectTrashButton: (
    index: number,
  ) =>
    `settings-integration-disconnection-whatsapp-disconnect-trash-button-${index}`,
  settingsIntegrationDisconnectionEmailInput: (index: number) =>
    `settings-integration-disconnection-email-input-${index}`,
  settingsIntegrationDisconnectionEmailDisconnectTrashButton: (index: number) =>
    `settings-integration-disconnection-email-disconnect-trash-button-${index}`,
  settingsIntegrationDisconnectionAddEmailButton:
    'settings-integration-disconnection-add-email-button',
  settingsIntegrationDisconnectionAddWhatsappButton:
    'settings-integration-disconnection-add-whatsapp-button',
  settingsManagePlanTabs: 'settings-manage-plan-tabs',
  settingsManagePlanCurrencyDropdown: 'settings-manage-plan-currency-dropdown',
  settingsManagePlanBackToTop: 'settings-manage-plan-back-to-top',
  settingsManagePlanHeaderCardStartup:
    'settings-manage-plan-header-card-startup',
  settingsManagePlanHeaderCardPro: 'settings-manage-plan-header-card-pro',
  settingsManagePlanHeaderCardPremium:
    'settings-manage-plan-header-card-premium',
  settingsManagePlanHeaderCardEnterprise:
    'settings-manage-plan-header-card-enterprise',
  settingsManagePlanFeatureListStartup:
    'settings-manage-plan-feature-list-startup',
  settingsManagePlanFeatureListPro: 'settings-manage-plan-feature-list-pro',
  settingsManagePlanFeatureListPremium:
    'settings-manage-plan-feature-list-premium',
  settingsManagePlanFeatureListEnterprise:
    'settings-manage-plan-feature-list-enterprise',
  settingsManagePlanTable: 'settings-manage-plan-table',
  settingsManagePlanCancelSection: 'settings-manage-plan-cancel-section',
} as const;
