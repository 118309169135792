import { onlineManager } from '@tanstack/react-query';
import { useEffect } from 'react';
import toast from 'react-hot-toast/headless';
import { useTranslation } from 'react-i18next';
import { PersistentToast } from './components/PersistentToast';

const OFFLINE_TOAST_ID = 'offline-toast';

export const OfflineToast = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = onlineManager.subscribe((isOnline) => {
      if (isOnline) {
        toast.remove(OFFLINE_TOAST_ID);
      } else {
        toast(
          (_t) => (
            <PersistentToast
              data-testid={OFFLINE_TOAST_ID}
              borderColor="orange.90"
              title={t('offline-toast.title', 'No internet connection')}
              description={t(
                'offline-toast.description',
                'You are currently offline. Some features may be unavailable. Please refresh the page once you’re back online.',
              )}
              closeButtonProps={{
                'data-testid': 'offline-toast-close-button',
                onClick: () => toast.dismiss(_t.id),
              }}
              actionButtonProps={{
                color: 'mustard',
                onClick: () => window.location.reload(),
                children: t('offline-toast.refresh-button', 'Refresh'),
              }}
            />
          ),
          {
            duration: Infinity,
            id: OFFLINE_TOAST_ID,
          },
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [t]);

  return null;
};
